import { createRouter, createWebHistory, type LinkRouteMeta } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView
		},
		{
			path: '/about',
			name: 'about',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('@/views/AboutView.vue'),
			meta: {
				linkLabel: 'About',
				linkNavBar: true,
				linkNavOrder: 100
			} as LinkRouteMeta
		},
		{
			path: '/fixtures',
			name: 'fixtures',
			component: () => import('@/components/TheFixtures.vue'),
			meta: {
				linkLabel: 'Fixtures',
				linkNavBar: true,
				linkNavOrder: 1
			} as LinkRouteMeta
		},
		{
			path: '/fixture/:id',
			name: 'fixture',
			component: () => import('@/views/FixtureView.vue')
		},
		{
			path: '/assemblies',
			name: 'assemblies',
			component: () => import('@/views/AssembliesView.vue'),
			meta: {
				linkLabel: 'Assemblies',
				linkNavBar: true,
				linkNavOrder: 2
			} as LinkRouteMeta
		},
		{
			path: '/assembly/:id',
			name: 'assembly',
			component: () => import('@/views/AssemblyView.vue')
		},
		{
			path: '/caxml',
			name: 'CAXML',
			component: () => import('@/components/TheCaxml.vue'),
			meta: {
				linkLabel: 'CAXML',
				linkNavBar: true,
				linkNavOrder: 3
			} as LinkRouteMeta
		},
		{
			path: '/projects',
			name: 'projects',
			component: () => import('@/views/ProjectsView.vue'),
			meta: {
				linkLabel: 'Projects',
				linkNavBar: true,
				linkNavOrder: 0
			} as LinkRouteMeta
		}
	]
})

export default router
